import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';

const useStyles = makeStyles(theme => ({
    
}));

const styles = StyleSheet.create({
    invoiceSection: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 20
    },
    sectionOne: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    textBold: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px'
    },
    sectionDateAndInvoice: {
        width: '50%', height: 'auto', flexDirection: 'row'
    },
    sectionChildDateAndInvoice: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '50%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTwo: {
        marginTop: 50, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    sectionThree: {
        marginTop: 10, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    dueDate: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '100%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTable: {
        width: '100%', height: 'auto', flexDirection: 'row'
    }
});

const WorkorderPdf = (props) => {
    const classes = useStyles();

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
                fontWeight: 'medium'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
                fontWeight: 'bold'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-BoldItalic.woff`,
                fontWeight: 'italic',
                fontStyle: 'italic'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-RegularItalic.woff`,
                fontWeight: 'regularitalic',
                fontStyle: 'regularitalic'
            }
        ]
    })

    return (
        <>
            <Document>
                <Page size="A4" style={{ padding: 20, paddingTop: 20 }}>
                    <View wrap={false} style={{ width: '100%', flexDirection: 'row', paddingLeft: 5, paddingRight: 5 }}>
                        <View style={{ width: '29%', height: 'auto', alignItems: 'center' }}>
                            <View>
                            {props.data?.company?.logoUrl?.includes('companylogo')
                                    ? <Image
                                        src={props.data.company.logoUrl}
                                        style={{ height: 85, width: 85}}
                                    />
                                    : <View style={{ height: 85 }} />
                                }
                                </View>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: '#006DB1', marginTop: 5 }}>
                            {props.data && props.data.company ? props.data.company.website : ""}
                            </Text>
                            { props.data && props.data.company && props.data.company.quoteShowLegalName &&<Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                Licensee: {props.data && props.data.company ? props.data.company.legalName : ""}
                            </Text>
                            }
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '40%', height: 'auto', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 15, color: '#006DB1' }}>
                                {props.data && props.data.company ? props.data.company.primaryPhoneNumber : ""}
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, color: 'black', marginTop: 5 }}>
                            {props.data && props.data.company && props.data.company.quoteTitleHeader}
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black', marginTop: 5 }}>
                            {props.data && props.data.company && props.data.company.quoteTitleBody}
                            </Text>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '29%', height: 'auto', alignItems: 'center' }}>
                            <View style={{ width: '100%', height: 90, border: 2, borderColor: '#006DB1', padding: 3, backgroundColor: '#E9ECF8' }}>
                                <View style={{ width: '100%', height: '50%', alignItems: 'center', justifyContent: 'center' }}>
                                    <View style={{ width: '60%', height: '100%', border: 1, borderColor: 'black', padding: 1 }}>
                                        <View style={{ width: '100%', height: '100%', border: 1, borderColor: 'black', padding: 1, alignItems: 'center', justifyContent: 'center' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 15, color: 'black', justifyContent: 'center' }}>
                                                Work Order
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ width: '100%', height: '50%', marginTop: 5 }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', marginTop: 5, width: '40%' }}>
                                            Service Date:
                                        </Text>
                                        <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '60%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', marginTop: 5, width: '40%' }}>
                                                {props.data && props.data.scheduledDate ? moment(props.data.scheduledDate).format('MM/DD/YYYY') : ""}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', marginTop: 5 }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', marginTop: 5, width: '40%' }}>
                                            Sent By:
                                        </Text>
                                        <View style={{ width: '60%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '100%', borderBottomWidth: 1, borderBottomColor: 'black', }}>
                                                {props.data && props.data.sentBy ? props.data.sentBy : ""}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 7 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '29%' }}>
                                    Company
                                </Text>
                                <View style={{ width: '1%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '70%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.data 
                                            && props.data.customer 
                                            && props.data.customer.companyName 
                                            && props.data.customer.customerType == 0 
                                                ? props.data.customer.companyName 
                                                : ""
                                        }
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ width: '100%', flexDirection: 'row', paddingLeft: 5, paddingRight: 5 }}>
                        <View style={{ width: '70%', height: 'auto', alignItems: 'center' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '22%' }}>
                                    Customer/Contact
                                </Text>
                                <View style={{ width: '1%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '77%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.data && props.data.customer && props.data.customer.primaryContact ? props.data.customer.primaryContact.firstName+" "+props.data.customer.primaryContact.lastName : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 15 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '10%' }}>
                                    Address
                                </Text>
                                <View style={{ width: '1%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '89%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.data && props.data.customer && props.data.customer.address ? props.data.customer.address.name : ""}
                                        {props.data && props.data.customer && props.data.customer.address && props.data.customer.address.address1 ? " "+props.data.customer.address.address1 : ""}
                                        {props.data && props.data.customer && props.data.customer.address ? " "+props.data.customer.address.city+", " : ""}
                                        {props.data && props.data.customer && props.data.customer.address ? props.data.customer.address.state.name+" " : ""}
                                        {props.data && props.data.customer && props.data.customer.address ? props.data.customer.address.zipCode : ""}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ width: '1%' }} />
                        <View style={{ width: '29%', height: 'auto', alignItems: 'center' }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '20%' }}>
                                    Phone
                                </Text>
                                <View style={{ width: '1%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '79%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.data && props.data.customer && props.data.customer.primaryContact && props.data.customer.primaryContact.phones.length > 0 ? props.data.customer.primaryContact.phones[0].phoneNumber : ""}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', marginTop: 15 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '21%' }}>
                                    E-mail
                                </Text>
                                <View style={{ width: '1%' }} />
                                <View style={{ borderBottomWidth: 1, borderBottomColor: 'black', width: '78%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        {props.data && props.data.customer && props.data.customer.primaryContact ? props.data.customer.primaryContact.email : ""}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ marginTop: 5, height: 'auto', flexDirection: 'row' }}>
                        <View style={{ width: '5%', backgroundColor: '#F9DCC4', borderWidth: 1, borderColor: '#E26E20', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                L
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                A
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                V
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                I
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                R
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                R
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                A
                            </Text>
                        </View>
                        <View style={{ width: '95%', backgroundColor: 'white' }}>
                            <View style={{ height: 'auto' }}>
                                <View wrap={false} style={{ }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '50%', border: 1, borderColor: '#006DB1', padding: 5, borderRight: 0 }}>
                                            {/* <View style={{ width: '50%', borderRight: 1 }}> */}
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                                        Job Description:
                                                    </Text>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '60%' }}>
                                                        {props.data && props.data.job ? props.data.job.description : ""}
                                                    </Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 5}}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                        Job Address:
                                                    </Text>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                        {props.data && props.data.job && props.data.job.jobAddress && props.data.job.jobAddress.primaryContact ? props.data.job.jobAddress.primaryContact.name : ""}
                                                    </Text>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <View style={{ width: '30%' }} />
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black',  width: '70%' }}>
                                                        {props.data && props.data.job && props.data.job.jobAddress && props.data.job.jobAddress.address1 ? props.data.job.jobAddress.address1 : ""}
                                                    </Text>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <View style={{ width: '30%' }} />
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                        {props.data && props.data.job && props.data.job.jobAddress ? props.data.job.jobAddress.city+", " : ""}
                                                        {props.data && props.data.job && props.data.job.jobAddress ? props.data.job.jobAddress.state.name+" " : ""}
                                                        {props.data && props.data.job && props.data.job.jobAddress ? props.data.job.jobAddress.zipCode : ""}
                                                    </Text>
                                                </View>
                                            {/* </View> */}
                                        </View>
                                        <View style={{ width: '50%', border: 1, borderColor: '#006DB1', padding: 5 }}>
                                            {/* <View style={{ width: '50%', borderRight: 1 }}> */}
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                        Name:
                                                    </Text>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                    {props.data && props.data.job && props.data.job.jobAddress && props.data.job.jobAddress.primaryContact ? props.data.job.jobAddress.primaryContact.name : ""}
                                                    </Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 5}}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                        Phone:
                                                    </Text>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                        {props.data && props.data.job && props.data.job.jobAddress && props.data.job.jobAddress.primaryContact ? props.data.job.jobAddress.primaryContact.phone : ""}
                                                    </Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', marginTop: 5}}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '30%' }}>
                                                        Email:
                                                    </Text>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black', width: '70%' }}>
                                                        {props.data && props.data.job && props.data.job.jobAddress && props.data.job.jobAddress.primaryContact ? props.data.job.jobAddress.primaryContact.email : ""}
                                                    </Text>
                                                </View>
                                            {/* </View> */}
                                        </View>
                                    </View>
                                    <View style={{ width: '100%', border: 1, borderColor: '#006DB1', padding: 5, borderTop: 0, marginTop: -1 }}>
                                        <View style={{ width: '100%', flexDirection: 'row' }}>
                                            <View style={{ flexDirection: 'row', width: '35%' }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                                    Scheduled For:
                                                </Text>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                    
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '35%' }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                                    Quoted By:
                                                </Text>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                    
                                                </Text>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '30%' }}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '40%' }}>
                                                    Quote Date:
                                                </Text>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                    {props.data && props.data.job && props.data.job.bidAppointment != null ? moment(props.data.job.bidAppointment).format('MM/DD/YYYY') : ""}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ width: '100%', border: 1, borderColor: '#006DB1', borderTop: 0, marginTop: -1 }}>
                                        <View style={{ width: '100%', height: 'auto', flexDirection: 'row' }}>
                                            <Text style={{ fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'center', padding: 6, borderRight: 0, borderLeft: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Quantity</Text>
                                            <Text style={{ fontSize: 10, width: '55%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'left', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Description</Text>
                                            <Text style={{ fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'center', padding: 6, borderRight: 0, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>Unit Price</Text>
                                            <Text style={{ fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, textAlign: 'center', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px', borderRight: 0, }}>Qnty Total</Text>
                                        </View>

                                        {props.data && props.data.jobPriceItems && props.data.job.displayOnPrintOutsEmails && props.data.jobPriceItems.map(function (value, index, array) {
                                            return <View key={index} style={styles.sectionTable}>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', textAlign: 'center', padding: 6, borderTop: 0, borderRight: 0, borderLeft: 0, }}>
                                                    {value.quantity}
                                                </Text>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '55%', border: 1, borderColor: '#006DB1', textAlign: 'left', padding: 6, borderRight: 0, borderTop: 0 }}>
                                                    {value.description}
                                                </Text>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', textAlign: 'right', padding: 6, borderRight: 0, borderTop: 0 }}>
                                                    {(value && value.price && `$${value.price.toFixed(2)}`) || '$0.00'}
                                                </Text>
                                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '15%', border: 1, borderColor: '#006DB1', textAlign: 'right', padding: 6, borderTop: 0, borderRight: 0, }}>
                                                    {(value && value.total && `$${value.total.toFixed(2)}`) || '$0.00'}
                                                </Text>
                                            </View>
                                        })}

                                        {props.data && props.data.job && props.data.job.discountPercent > 0 && props.data.job.discountDollars > 0
                                        ? <View style={styles.sectionTable}>
                                            <Text style={{ width: '15%' }}></Text>
                                            <Text style={{ width: '55%'}}></Text>
                                            <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                                Discount ({props.data.job.discountPercent}%)
                                            </Text>
                                            <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                                {(props.data && props.data.job && `$${props.data.job.discountDollars.toFixed(2)}`) || '$0.00'}
                                            </Text>
                                        </View>
                                        : null }

                                        <View style={styles.sectionTable}>
                                            <Text style={{ width: '15%' }}></Text>
                                            <Text style={{ width: '55%'}}></Text>
                                            <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                                Sub-Total
                                            </Text>
                                            <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                                {(props.data && props.data.subTotal && `$${props.data.subTotal.toFixed(2)}`) || '$0.00'}
                                            </Text>
                                        </View>
                                        
                                        <View style={styles.sectionTable}>
                                            <Text style={{ width: '15%' }}></Text>
                                            <Text style={{ width: '55%'}}></Text>
                                            <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                                Tax ({props.data && props.data.job ? props.data.job.taxPercent : "0"}%)
                                            </Text>
                                            <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0 }}>
                                                {(props.data && props.data.taxDollar && `$${props.data.taxDollar.toFixed(2)}`) || '$0.00'}
                                            </Text>
                                        </View>
                                        <View style={styles.sectionTable}>
                                            <Text style={{ width: '15%' }}></Text>
                                            <Text style={{ width: '55%'}}></Text>
                                            <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderBottom: 0, borderLeft: 0, borderRight: 0, borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px' }}>
                                                Total
                                            </Text>
                                            <Text style={{ width: '15%', border: 1, borderColor: '#006DB1', borderTop: 0, fontSize: 10,textAlign: 'right', padding: 6, fontFamily: 'Roboto', fontWeight: 'medium', borderRight: 0, borderBottom: 0 }}>
                                                {(props.data && props.data.totalAmount && `$${props.data.totalAmount.toFixed(2)}`) || '$0.00'}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ height: 'auto', backgroundColor: '#E3ECF6', justifyContent: 'center', paddingLeft: 5, borderTop: 1, borderRight: 1, borderBottom: 1, borderColor: '#0D76BA' }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#0D76BA', marginRight: 5 }}>
                                        PART 2
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20' }}>
                                        ATTENTION CUSTOMER: PLEASE READ!
                                    </Text>
                                </View>
                            </View>
                            <View style={{ height: 'auto', paddingLeft: 5, paddingRight: 5 }}>
                                <View style={{ alignItems: 'center' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                        GLASS SAFE™ - OUR CLEANING PROCESS
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', textAlign: 'center', marginTop: 3 }}>
                                    {props.data && props.data.company ? props.data.company.legalName : ""} window cleaning technicians are trained to clean windows according to the GANA/IWCA Bulletin 01-0116
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                        Proper Procedures for Cleaning Architectural Glass Products.
                                    </Text>
                                </View>
                            </View>
                            <View style={{ marginTop: 5, height: 'auto', backgroundColor: '#4D92CA', justifyContent: 'center', paddingLeft: 5, paddingRight: 5, borderTop: 1, borderRight: 1, borderBottom: 1, borderColor: '#0D76BA' }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'white', textAlign: 'center' }}>
                                        PART 3 » TECHNICIAN PLEASE REVIEW CLEANING PROCESSES ON BACK SIDE WITH CUSTOMER AT THIS TIME «
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ flexDirection: 'row', width: '100%', marginTop: 10, paddingLeft: 5, paddingRight: 5 }}>
                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black' }}>
                        {props.data && props.data.company ? props.data.company.workOrderForm : ""} 
                        </Text>              
                    </View>

                    <View wrap={false} style={{ marginTop: 5 }}>
                        <View style={{ width: '100%', height: 30, backgroundColor: '#F5C6A1', border: 2, borderColor: '#E26E20', justifyContent: 'flex-end', paddingLeft: 5, paddingRight: 5 }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                                <View style={{ flexDirection: 'row', width: '50%', marginRight: 5 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', width: '60%' }}>
                                        Customer Approval Signature
                                    </Text>
                                    <View style={{ width: '1%' }} />
                                    <View style={{ borderBottomWidth: 0, borderBottomColor: 'black', width: '10%' }}>
                                    {props.data && props.data.signatures && props.data.signatures.length > 0  && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ).length > 0 &&
                                                <Image src={props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } )[0].signatureUrl} width="100%" height="100%" />}
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row', width: '15%', marginRight: 5 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', width: '25%' }}>
                                        Date
                                    </Text>
                                    <View style={{ width: '1%' }} />
                                    <View style={{ borderBottomWidth: 0, borderBottomColor: 'black', width: '75%' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                            {props.data && props.data.signatures && props.data.signatures.length > 0  && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } ).length > 0 &&
                                                moment(props.data.signatures.filter(function(item) { return item.signaturePosition == 1 } )[0].signatureDate).format('MM/DD/YYYY')}
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row', width: '35%' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: 'black', width: '65%' }}>
                                        Total Amount Due 
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontStyle: 'regularitalic', fontSize: 7 }}>
                                            (including Tax)
                                        </Text>: $ 
                                    </Text>
                                    <View style={{ borderBottomWidth: 0, borderBottomColor: 'black', width: '35%' }}>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                            {props.data ? props.data.jobTotal : ""}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ marginTop: 5, height: 'auto', flexDirection: 'row' }}>
                        <View style={{ width: '5%', backgroundColor: '#F9DCC4', borderWidth: 1, borderColor: '#E26E20', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                E
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                R
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                U
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                T
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                R
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                A
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                P
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                E
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                D
                            </Text>
                        </View>
                        <View style={{ width: '95%', backgroundColor: 'white' }}>
                            <View style={{ height: 'auto', backgroundColor: '#E3ECF6', justifyContent: 'center', paddingLeft: 5, borderTop: 1, borderRight: 1, borderBottom: 1, borderColor: '#0D76BA' }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#0D76BA', marginRight: 5 }}>
                                        PART 4
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20' }}>
                                        CUSTOMER WALKTHROUGH ENSURES YOUR SATISFACTION
                                    </Text>
                                </View>
                            </View>
                            <View style={{ height: 'auto', backgroundColor: 'white', justifyContent: 'center', padding: 5, borderTop: 1, borderRight: 1, borderBottom: 1, borderColor: '#0D76BA' }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                        When our service is complete, please conduct a walk through with our crew to ensure you are 100% satisfied with our work. In the event of a cancellation, please
                                        call our office to reschedule; <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: '#0D76BA' }}>{props.data && props.data.company ? props.data.company.primaryPhoneNumber : ""}</Text>. If you are not home at the time of service, {props.data && props.data.company ? props.data.company.legalName : ""} will <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black' }}>NOT</Text> use non-routine cleaning methods.
                                    </Text>
                                </View>
                            </View>
                            <View style={{ height: 'auto', paddingLeft: 5, paddingRight: 5, borderBottomWidth: 1, borderBottomColor: '#0D76BA' }}>
                                <View style={{ }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                        Option 1
                                    </Text>
                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={{ width: '49%' }}>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={{ width: 7, height: 7, marginRight: 5 }}>
                                                    <View style={{ width: '100%', height: '100%', borderWidth: 1 }}></View>
                                                </View>
                                                <View style={{ width: '95%' }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        Are all windows cleaned, screens cleaned & installed properly and sills cleaned to expectations?
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={{ width: 7, height: 7, marginRight: 5 }}>
                                                    <View style={{ width: '100%', height: '100%', borderWidth: 1 }}></View>
                                                </View>
                                                <View style={{ width: '95%' }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        Did your crew take off their shoes & protect any interior walls with towels if a ladder was used inside?
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 5, width: '50%' }}>
                                                    <View style={{ width: 7, height: 7, marginRight: 5 }}>
                                                        <View style={{ width: '100%', height: '100%', borderWidth: 1 }}></View>
                                                    </View>
                                                    <View style={{ width: '95%' }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                            Was the crew kind, courteous & professional?
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', width: '50%' }}>
                                                    <View style={{ width: 7, height: 7, marginRight: 5 }}>
                                                        <View style={{ width: '100%', height: '100%', borderWidth: 1 }}></View>
                                                    </View>
                                                    <View style={{ width: '95%' }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        Were they in uniform?
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ width: '2%' }} />
                                        <View style={{ width: '49%' }}>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={{ width: 7, height: 7, marginRight: 5 }}>
                                                    <View style={{ width: '100%', height: '100%', borderWidth: 1 }}></View>
                                                </View>
                                                <View style={{ width: '95%' }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        Are all window treatments & furniture moved back to the original positions?
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={{ width: 7, height: 7, marginRight: 5 }}>
                                                    <View style={{ width: '100%', height: '100%', borderWidth: 1 }}></View>
                                                </View>
                                                <View style={{ width: '95%' }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        Has all work been performed to your expectations?
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 5, width: '70%' }}>
                                                    <View style={{ width: '95%' }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                            Would you like a follow up call from a manager?
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', width: '30%' }}>
                                                    <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                        <View style={{ width: 7, height: 7, marginRight: 5 }}>
                                                            <View style={{ width: '100%', height: '100%', borderWidth: 1 }}></View>
                                                        </View>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                            Yes
                                                        </Text>
                                                    </View>
                                                    
                                                    <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                        <View style={{ width: 7, height: 7, marginRight: 5 }}>
                                                            <View style={{ width: '100%', height: '100%', borderWidth: 1 }}></View>
                                                        </View>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                            No
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ marginTop: 10 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                        Option 2
                                    </Text>
                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={{ width: '49%' }}>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={{ width: 7, height: 7, marginRight: 5 }}>
                                                    <View style={{ width: '100%', height: '100%', borderWidth: 1 }}></View>
                                                </View>
                                                <View style={{ width: '95%' }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        I have conducted a walk through and brought any concerns to the crew’s attention.I would prefer to
                                                        submit online comments. 
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ width: '2%' }} />
                                        <View style={{ width: '49%' }}>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <View style={{ width: 7, height: 7, marginRight: 5 }}>
                                                    <View style={{ width: '100%', height: '100%', borderWidth: 1 }}></View>
                                                </View>
                                                <View style={{ width: '95%' }}>
                                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 9, color: 'black' }}>
                                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 9, color: 'black' }}>
                                                                STAFF ONLY:
                                                            </Text> Customer was not home at the time of the walk through.
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View wrap={false} style={{ marginTop: 5, height: 'auto' }}>
                        <View style={{ width: '100%', height: 100, border: 1, borderColor: '#006DB1' }}>
                            {/* <View style={{ width: '100%', height: '35%', backgroundColor: '#006DB1', padding: 5 }}>
                                <View style={{ borderTop: 2, borderTopColor: 'white', borderBottom: 2, borderBottomColor: 'white', width: '100%', height: '100%', alignItems: 'center', paddingTop: 3, paddingBottom: 3, justifyContent: 'center' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 15, color: 'white' }}>
                                        FRANCHISES AVAILABLE NATIONWIDE!
                                    </Text>
                                </View>
                            </View> */}
                            <View style={{ width: '100%', height: 'auto', backgroundColor: '#E9ECF8', flexDirection: 'row' }}>
                                {/* <View style={{ width: '30%', height: '100%' }}>
                                    <View style={{ width: '100%', height: '100%', backgroundColor: '#006DB1', padding: 5, alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ borderTop: 2, borderTopColor: 'white', borderBottom: 2, borderBottomColor: 'white', width: '100%', alignItems: 'center', paddingTop: 3, paddingBottom: 3, justifyContent: 'center' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'white' }}>
                                                FRANCHISES AVAILABLE!
                                            </Text>
                                        </View>
                                        <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 17, color: 'white' }}>
                                            NATIONWIDE
                                        </Text>
                                    </View>
                                </View> */}
                                <View style={{ width: '1%' }} />
                                <View style={{ width: '68%' }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '3%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                                1.
                                            </Text>
                                        </View>
                                        <View style={{ width: '97%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                Fully insured company, with Squeegee Squad employees thoroughly trained by owners and management.
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '3%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                                2.
                                            </Text>
                                        </View>
                                        <View style={{ width: '97%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                Experienced crew, always in uniform.
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '3%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                                3.
                                            </Text>
                                        </View>
                                        <View style={{ width: '97%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                Fast, efficient workers maintaining focus on quality service and protection inside and outside of your home.
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '3%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                                4.
                                            </Text>
                                        </View>
                                        <View style={{ width: '97%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                Safety training implemented with our staff regularly.
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '3%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                                5.
                                            </Text>
                                        </View>
                                        <View style={{ width: '97%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                Employee background checks mandated during employee hiring process.
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <View style={{ width: '3%'}}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 8, color: '#0E76BA', marginTop: 5 }}>
                                                6.
                                            </Text>
                                        </View>
                                        <View style={{ width: '97%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 8, color: 'black', marginTop: 5 }}>
                                                Consistently given 5-star online reviews - we get the BEST ratings in the industry
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ width: '1%' }} />
                                <View style={{ width: '29%', alignItems: 'center', justifyContent: 'center' }}>
                                    {props.data?.company?.logoUrl?.includes('companylogo')
                                        ? <Image
                                            src={props.data.company.logoUrl}
                                            style={{ height: '100%', width: '100%'}}
                                        />
                                        : <View style={{ height: '100%' }} />
                                    }
                                </View>
                                <View style={{ width: '1%' }} />
                            </View>
                        </View>
                    </View>

                    <View style={{ marginTop: 5, height: 'auto', flexDirection: 'row' }}>
                        <View style={{ width: '5%', backgroundColor: '#F9DCC4', borderWidth: 1, borderColor: '#E26E20', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                L
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                A
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                V
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                I
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                R
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                R
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20', transform: `rotate(270deg)`, marginTop: -6 }}>
                                A
                            </Text>
                        </View>
                        <View style={{ width: '95%', backgroundColor: 'white' }}>
                            <View style={{ height: 'auto', backgroundColor: '#E3ECF6', justifyContent: 'center', paddingLeft: 5, borderTop: 1, borderRight: 1, borderBottom: 1, borderColor: '#0D76BA' }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#0D76BA', marginRight: 5 }}>
                                        PART 3
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: '#E26E20' }}>
                                        GLASS SAFE™ - OUR CLEANING PROCESS
                                    </Text>
                                </View>
                            </View>
                            <View style={{ height: 'auto', borderBottomWidth: 1, borderBottomColor: '#0D76BA' }}>
                                <View style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                        ROUTINE GLASS CLEANING
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                        Unless requested in writing by the homeowner or client, our window cleaners will only use routine window glass cleaning methods. Routine window glass cleaning includes the use of
                                        tools and mild detergents such as: soft cloths, strip washers, squeegees, non-abrasive pads, water-fed poles, and mild detergents.
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                        • Routine cleaning methods <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', textDecoration: 'underline', fontSize: 10, color: 'black', marginTop: 3 }}>
                                        will remove
                                    </Text> normal dirt and debris that can adhere between frequent window cleaning services. 
                                    </Text>
                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={{ width: '50%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                                • Routine cleaning methods <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', textDecoration: 'underline', fontSize: 10, color: 'black', marginTop: 3 }}>
                                                may not remove
                                    </Text> all stains and weathering that can
                                                occur due to infrequent cleaning or when windows are improperly protected during
                                                construction or remodeling such as: hard water, artillery fungus spores, sap, tar, paint,
                                                adhesives, varnish, mortar, silicone, and more.
                                            </Text>
                                        </View>
                                        <View style={{ width: '50%', justifyContent: 'center' }}>
                                            <View style={{ width: '100%', alignItems: 'center' }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', width: '80%' }}>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group1Images/Group1Image1.jpg" />
                                                    </View>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group1Images/Group1Image2.jpg" />
                                                    </View>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group1Images/Group1Image3.jpg" />
                                                    </View>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group1Images/Group1Image4.jpg" />
                                                    </View>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group1Images/Group1Image5.jpg" />
                                                    </View>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group1Images/Group1Image6.jpg" />
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ height: 'auto', borderBottomWidth: 1, borderBottomColor: '#0D76BA' }}>
                                <View style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                        NON-ROUTINE GLASS RESTORATION
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                        Window restoration using non-routine cleaning methods will only be performed if after the homeowner or client has read this disclosure, and you agree in writing to the use of nonroutine methods by signing the authorization below. Non-routine window cleaning may include, but is not limited to, use of: metal razor blades, acids, steel wool, polishing compounds,
                                        and more. 
                                    </Text>
                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={{ width: '15%', alignItems: 'center', justifyContent: 'center' }}>
                                            <Image style={{ width: '70%' }} src="/images/quote_invoice/Group2Images/Group2Image1.jpg" />
                                        </View>
                                        <View style={{ width: '85%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', marginTop: 3 }}>
                                            Razor Blade Option:
                                            </Text> The Glass Association of North America neither condones nor condemns the use of metal razor blades during non-routine cleaning procedures. It
                                                is widely understood that metal razor blades may be the only effective and effi cient method to clean the following stains and damage from glass:hard water, artillery
                                                fungus spores, sap, tar, paint, adhesives, varnish, mortar, silicone, and more.
                                            </Text>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 7 }}>
                                                Although metal razor blades have historically been a standard tool of the trade for professional window cleaners, many manufacturers no longer recommend razor
                                                blade use because scratches can occur on the glass when razors interact with surface defects/fabricating debris that may be present on tempered and heat treated
                                                glass. Surface defects/fabricating debris are not 
                                            </Text>
                                        </View>
                                    </View>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                        present on all tempered and heat treated glass and occur as the result of the manufacturer’s failure to completely clean the glass
                                        during the heat treatment process. It is also possible that using metal razor blades may also result in damage to refl ective coatings on glass. Most glass manufacturers prohibit the use
                                        of razor blades when cleaning glass, however, the glass industry acknowledges that razor blades must be used to remove certain stains as referred to above. <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 10, color: 'black', marginTop: 3 }}>
                                        We are not liable for any
                                        damages that result from scratches to the glass.
                                    </Text>
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 7 }}>
                                        Before we go ahead and attempt to clean and restore your glass with razor blades, we want to be sure that you have read this important disclosure about the use of razor blades.
                                    </Text>
                                </View>
                            </View>
                            <View style={{ backgroundColor: '#0E76BA', height: 'auto', padding: 5 }}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, color: 'white', textAlign: 'center' }}>
                                    BY REQUESTING THAT WE USE RAZOR BLADES IN CLEANING YOUR GLASS, YOU AGREEE THAT WE ARE NOT LIABLE IN ANY WAY FOR ANY DAMAGES THAT OCCUR AS A RESULT OF SCRATCHES TO YOUR WINDOWS. 
                                </Text>
                            </View>
                            <View style={{ height: 'auto', borderBottomWidth: 1, borderBottomColor: '#0D76BA' }}>
                                <View style={{ padding: 5 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black' }}>
                                        I, _____________________ (customer), having been informed of the possible damages that may occur, and acknowledging the responsibility and liability for and damages
                                        resulting from the use of the razor blades, request that you perform cleaning services with the use of razor blades
                                    </Text>

                                    <View style={{ flexDirection: 'row', width: '100%' }}>
                                        <View style={{ width: '10%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                (Signature) 
                                            </Text>
                                        </View>
                                        <View style={{ width: '10%', height: '100%' }}>
                                            {props.data && props.data.signatures && props.data.signatures.length > 0 && props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } ).length > 0
                                                ? <Image src={props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } )[0].signatureUrl} width="100%" height="100%" />
                                                : <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                    
                                                </Text>
                                            }
                                        </View>
                                        <View style={{ width: '10%' }}></View>
                                        <View style={{ width: '70%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                (Date) {'\u00A0'}
                                                {props.data && props.data.signatures && props.data.signatures.length > 0  && props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } ).length > 0
                                                    ? moment(props.data.signatures.filter(function(item) { return item.signaturePosition == 2 } )[0].signatureDate).format('MM/DD/YYYY') 
                                                    : <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                            
                                                        </Text>
                                                }
                                            </Text> 
                                        </View>
                                    </View>

                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={{ width: '20%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                (Printed Name / Title) 
                                            </Text>
                                        </View>
                                        <View style={{ width: '10%' }}>
                                            {props.data && props.data.signatures && props.data.signatures.length > 0 && props.data.signatures.filter(function(item) { return item.signaturePosition == 3 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 3 } ).length > 0 
                                                ? <Image src={props.data.signatures.filter(function(item) { return item.signaturePosition == 3 } )[0].signatureUrl} width="100%" height="100%" />
                                                : <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                        
                                                    </Text>
                                            }
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ height: 'auto', borderBottomWidth: 1, height: 120, borderBottomColor: '#0D76BA' }}>
                                <View style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                        ALTERNATIVE NON-ROUTINE GLASS RESTORATION
                                    </Text>
                                    <View style={{ width: '100%', flexDirection: 'row' , }}>
                                        <View style={{ width: '65%' , height: '100%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 3 }}>
                                                Although acids, super fi ne steel wool, polishing compounds and other chemicals are generally considered safe for cleaning
                                                glass, your window manufacturer may not recommend using these alternative types of non-routine glass restoration
                                                methods. If you have refl ective coatings on the interior or exterior surfaces of your glass, then these coatings may be altered
                                                and perceived as damaged during the cleaning/restoration process using these alternative non-routine cleaning methods
                                            </Text>
                                        </View>
                                        <View style={{ width: '35%', justifyContent: 'center' }}>
                                            <View style={{ width: '100%', alignItems: 'center' }}>
                                                <View style={{ flexDirection: 'row' ,alignItems: 'center', width: '80%'  }}>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group2Images/Group2Image2.jpg" />
                                                    </View>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group2Images/Group2Image3.jpg" />
                                                    </View>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group2Images/Group2Image4.jpg" />
                                                    </View>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group2Images/Group2Image5.jpg" />
                                                    </View>
                                                    <View style={{ width: '20%' }}>
                                                        <Image src="/images/quote_invoice/Group2Images/Group2Image6.jpg" />
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ backgroundColor: '#0E76BA', height: '80', padding: 5 , marginTop: 10}}>
                                <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 12, color: 'white', textAlign: 'center', textTransform: 'uppercase' }}>
                                    BY ReQUeSTING THAT We USe ACID, POLISHING COMPOUNDS, ABRASIveS, OR OTHeR CHeMICALS TO ATTeMPT TO ReSTORe YOUR GLASS, YOU
                                    AGRee THAT We ARe NOT LIABLe IN ANY WAY FOR ANY DAMAGeS THAT MAY OCCUR AS A ReSULT OF THeSe ATTeMPTS TO ReSTORe YOUR
                                    WINDOWS
                                </Text>
                            </View>
                            <View style={{ height: 'auto', borderBottomWidth: 1, borderBottomColor: '#0D76BA' }}>
                                <View style={{ padding: 5 }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black' }}>
                                        I, _____________________ (customer), having been informed of the possible damages that may occur, and acknowledging the responsibility and liability for and damages
                                        resulting from the use of the methods described above, request that you perform window restoration services with the methods and chemicals referred to above.
                                    </Text>

                                    <View style={{ flexDirection: 'row', width: '100%' }}>
                                        <View style={{ width: '10%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                (Signature) 
                                            </Text>
                                        </View>
                                        <View style={{ width: '10%', height: '100%' }}>
                                            {props.data && props.data.signatures && props.data.signatures.length > 0 && props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } ).length > 0
                                                ? <Image src={props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } )[0].signatureUrl} width="100%" height="100%" />
                                                : <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                    
                                                </Text>
                                            }
                                        </View>
                                        <View style={{ width: '10%' }}></View>
                                        <View style={{ width: '70%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                (Date) {'\u00A0'}
                                                {props.data && props.data.signatures && props.data.signatures.length > 0  && props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } ).length > 0
                                                    ? moment(props.data.signatures.filter(function(item) { return item.signaturePosition == 4 } )[0].signatureDate).format('MM/DD/YYYY') 
                                                    : <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                            
                                                        </Text>
                                                }
                                            </Text> 
                                        </View>
                                    </View>

                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={{ width: '20%' }}>
                                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                (Printed Name / Title) 
                                            </Text>
                                        </View>
                                        <View style={{ width: '10%' }}>
                                            {props.data && props.data.signatures && props.data.signatures.length > 0 && props.data.signatures.filter(function(item) { return item.signaturePosition == 5 } ) && props.data.signatures.filter(function(item) { return item.signaturePosition == 5 } ).length > 0 
                                                ? <Image src={props.data.signatures.filter(function(item) { return item.signaturePosition == 5 } )[0].signatureUrl} width="100%" height="100%" />
                                                : <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, color: 'black', marginTop: 10 }}>
                                                        
                                                    </Text>
                                            }
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={{ marginTop: 10, minHeight: 50, width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ width: '20%' }}>
                            {props.data?.company?.logoUrl?.includes('companylogo')
                                    ? <Image
                                        src={props.data.company.logoUrl}
                                        style={{ height: '40', width: '100%'}}
                                    />
                                    : <View style={{ }} />
                                }
                        </View>
                        <View style={{ width: '20%', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'center' }}>
                                {props.data?.company?.legalName ?? " "}
                            </Text>
                        </View>
                        <View style={{ width: '25%', alignItems: 'center' }}>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'center' }}>
                            {props.data && props.data.company ? props.data.company.website : ""}
                            </Text>
                        </View>
                        <View style={{ width: '35%' }}>
                            {/* <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                                Phone: 000.000.0000
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                                Email: name@squeegeesquad.com
                            </Text> */}
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                                {props.data && props.data.company && props.data.company.addresses && props.data.company.addresses.length > 0 ? (props.data.company.addresses[0].address1 || '') : ""}
                            </Text>
                            {props.data && props.data.company && props.data.company.addresses && props.data.company.addresses.length > 0 && props.data.company.addresses[0].address2 && props.data.company.addresses[0].address2 != ""
                                ? <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                                    {props.data.company.addresses[0].address2}
                                </Text>
                                : null 
                            }
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                                {props.data && props.data.company && props.data.company.addresses && props.data.company.addresses.length > 0 ? props.data.company.addresses[0].city+", " : ""}
                                {props.data && props.data.company && props.data.company.addresses && props.data.company.addresses.length > 0 ? props.data.company.addresses[0].state.name+" " : ""}
                                {props.data && props.data.company && props.data.company.addresses && props.data.company.addresses.length > 0 ? props.data.company.addresses[0].zipCode : ""}
                            </Text>
                            <Text style={{ fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 11, color: 'black', textAlign: 'right' }}>
                                {props.data && props.data.company && props.data.company.email ? "Email: "+props.data.company.email : ""}
                            </Text>
                        </View>
                    </View>

                </Page>
            </Document>
        </>
    )
}

WorkorderPdf.propTypes = {
    
}

export default WorkorderPdf;
